class Timer {
  constructor(seconds, callback) {
    this.seconds = seconds;
    this.callback = callback;
    this.timerID = null;
    this.remainingTime = seconds;

    this.hasFired = false;
  }

  start() {
    if(this.timerID || this.hasFired){
      return;
    }
    this.endTime = Date.now() + this.remainingTime * 1000;
    this.timerID = setTimeout(() => {
      this.hasFired = true;
      this.callback();
      this.stop();
    }, this.remainingTime * 1000);
  }

  pause() {
    clearTimeout(this.timerID);
    this.timerID = null;
    this.remainingTime = Math.max(this.endTime - Date.now(), 0) / 1000; // Convert milliseconds back to seconds
  }

  stop() {
    clearTimeout(this.timerID);
    this.timerID = null;
  }
}

export default Timer;
