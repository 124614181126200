import { ref, onMounted, onUnmounted } from 'vue';
import Timer from '~/utils/timer.js';
import { useStore } from 'vuex';

interface TimerConfig {
  duration: number;
  name: string;
}

interface EventData {
  eventName: string;
  props: {
    durationInSeconds: number;
  };
}

export function useSessionLengthEvents() {
  const timers = ref<Timer[]>([]);
  const store = useStore();

  function initTimers() {
    const timerConfigs: TimerConfig[] = [
      { duration: 30, name: 'Session: 30 sec' },
      { duration: 60, name: 'Session: 1 min' },
      { duration: 180, name: 'Session: 3 min' },
    ];

    timers.value = timerConfigs.map((timerData) => {
      const eventData: EventData = {
        eventName: timerData.name,
        props: {
          durationInSeconds: timerData.duration,
        },
      };

      const timer = new Timer(timerData.duration, () => {
        store.dispatch('tracker/trackSessionLengthEvent', eventData);
      });

      timer.start();
      return timer;
    });
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      timers.value.forEach((timer) => timer.pause());
    } else {
      timers.value.forEach((timer) => timer.start());
    }
  }

  function cleanupTimers() {
    timers.value.forEach((timer) => timer.stop());
  }

  onMounted(() => {
    initTimers();
    document.addEventListener('visibilitychange', handleVisibilityChange);
  });

  onUnmounted(() => {
    cleanupTimers();
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  });
}
