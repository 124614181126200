import { onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import type { Store } from 'vuex';

interface RootState {
  bodyClassOverflowHidden: boolean;
}

export const useBodyClassOverflowHidden = (): void => {
  const store: Store<RootState> = useStore();

  onMounted(() => {
    if (store.state.bodyClassOverflowHidden) {
      document.body.classList.add('overflow-hidden');
    }
  });

  watch(
    () => store.state.bodyClassOverflowHidden,
    (newValue: boolean) => {
      if (newValue) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
  );
};
